import * as THREE from "three"
import React, {useState, useRef, Suspense } from "react";
import { Plane, Environment, OrbitControls, TransformControls, softShadows } from "@react-three/drei";
import { Canvas, useFrame }   from "react-three-fiber";
import {proxy, useProxy} from "valtio";
import "./App.css";

import { useSpring, a } from "react-spring/three";

//import Applicase from "./models/Applicase";
//import Model from "./models/Nogroup";
import Model from "./models/Isotherm";
//import Model from "./models/Erste";

import Box from "./models/Box";
import Stage from "./models/Stage";
import Picker from "./components/Picker";


const state = proxy({
  current: null,
  items: {
    body: "#e4aa16",
  },
});

const App = () => {
  const [pickerActive, setPickerActive] = React.useState(false);
  const [isLidRaised, setIsLidRaised] = React.useState(false);

  const toggleLid = (e) => {
    e.preventDefault();
    setIsLidRaised(!isLidRaised);
  }
  const togglePicker = (e) => {
    e.preventDefault();
    setPickerActive(!pickerActive);
  }

  return (
    <>
      <nav>
        <a className="button" onClick={(e) => toggleLid(e)}>
          {isLidRaised ? "Haube senken" : "Haube abheben"}
        </a>
      </nav>
      <Canvas
        colorManagement
        shadowMap
        pixelRatio={[1, 2]}
        style={{ width: "100%", height: "100%" }}
        camera={{
          zoom: 3,
          rotation: [0, 0, 0],
          position: [0, 2, 2.75],
          fov: 110,
        }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.ACESFilmicToneMapping;
          gl.toneMappingExposure = 2;
        }}
      >
        <color attach="background" args={["#cccccc"]} />
        <Suspense fallback={null}>
          <ambientLight intensity={0.7} />
          <Model
            position={[0, 0.1, 0]}
            isLidRaised={isLidRaised}
            setIsLidRaised={setIsLidRaised}
          />
          <Plane
            material-color="#666666"
            receiveShadow
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -1, 0]}
            args={[1000, 1000]}
          >
            <meshStandardMaterial attach="material" color="white" />
          </Plane>
          <directionalLight
            position={[0,.1,0]}
            intensity={2.5}
            castShadow
            lookAt={Model}
            shadow-mapSize-height={1024}
            shadow-mapSize-width={1024}
            shadow-camera-far={50}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
          />
          <OrbitControls
            enableZoom={true}
            minDistance={2}
            maxDistance={20}
            maxPolarAngle={Math.PI / 1.8}
            minPolarAngle={-Math.PI / 2}
          />
          <Environment files="CGSkies_0338_free.hdr" background />
        </Suspense>
      </Canvas>
      <Picker state={state} pickerActive={pickerActive} />
    </>
  );
};

export default App;
