import React from 'react'

const TrapezBlech = ({materials, nodes}) => {
	return (
    <group>
      <mesh
        material={materials["Plastic.weiss"]}
        geometry={
          nodes["Blechzuschnitt_L\\X\\E4ngs_ISO-THERM_AK_30_150x250"].geometry
        }
      />
      <mesh
        material={materials["Plastic.weiss"]}
        geometry={
          nodes["Blechzuschnitt_L\\X\\E4ngs_ISO-THERM_AK_30_150x250001"]
            .geometry
        }
      />
      <mesh
        material={materials["Plastic.weiss"]}
        geometry={nodes["Blechzuschnitt_Quer_ISO-THERM_AK_30_150x250"].geometry}
      />
      <mesh
        material={materials["Plastic.weiss"]}
        geometry={
          nodes["Blechzuschnitt_Quer_ISO-THERM_AK_30_150x250001"].geometry
        }
      />
    </group>
  );
}

export default TrapezBlech
