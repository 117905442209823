/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import TrapezBlech from './isothermParts/TrapezBlech'
import Deckel from './isothermParts/Deckel'

export default function Model({setIsLidRaised, isLidRaised, ...otherProps}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/isotherm.glb')

  return (
    <group ref={group} {...otherProps} dispose={null}>
      <Deckel
        materials={materials}
        nodes={nodes}
        setIsLidRaised={setIsLidRaised}
        isLidRaised={isLidRaised}
      />
      <TrapezBlech
        materials={materials}
        nodes={nodes}
        />

      <mesh
        castShadow
        material={materials["Plastic.weiss"]}
        geometry={nodes["9198_ISO-THERM-AK_30_schr\\X\\E4g_150x250"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_NG_250.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_NEU_02.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_NEU_02_links.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Motorkonsole_TiMotion_100x150_AK_350_Hub.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_01_Motor.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["TA23-533"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["TA23-533_Kolben"].geometry}
      />
      <mesh
        castShadow
        material={materials["Plastic.weiss"]}
        geometry={
          nodes["10268_Stufen-L\\X\\FCfterrahmenprofil_2D_1315x2315"].geometry
        }
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Winkelbefestigung_Querverriegelung.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Winkelbefestigung_Querverriegelung_rechts.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_25x25_NG_250.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Befestigungswinkel_Pilzkopf.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Befestigungswinkel_2_Pilzkopf.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Einstellblech_Verriegelung_3.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Abdeckkonsole_rechts.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Halter_Pilzkopfverriegelung.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Halter_Pilzkopfverriegelung_rechts.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Abdeckkonsole.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Abdeckblech_Motor_02.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ORB-MB-00001-APO"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ORB-OP-12001-APO"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_130_Lang.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_130_Lang001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_130_Lang_mit_Bohrung.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_130_Lang_mit_Bohrung001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_NG_150_01.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Rohr_20x40_NG_150_01001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_Schwinge_Glas_100x100.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_Schwinge_Glas_100x100001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Seitenverbindung_Schiene_NG_150.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Seitenverbindung_Schiene_NG_150001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["VA-Kugelk\\X\\E4fig_7,93_Nadella_340mm"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["VA-Kugelk\\X\\E4fig_7,93_Nadella_340mm001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["VA-Kugelk\\X\\E4fig_7,93_Nadella_340mm002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["VA-Kugelk\\X\\E4fig_7,93_Nadella_340mm003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_1_1275.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_1_1275001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_2_1275.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_2_1275001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_3_1275.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Profil_3_1275001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_2338_-_5_h8_x_45"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_2338_-_5_h8_x_45001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Ausgleichsscheibe.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Ausgleichsscheibe001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_BG_Schwinge.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_BG_Schwinge001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Ausgleichsblech_2.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Ausgleichsblech_2001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M8_x_40"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M8_x_40001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4004"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4005"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4006"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4007"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4008"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_9021_-_8,4009"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_917_-_M8_x_1,25_13"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_917_-_M8_x_1,25_13001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_917_-_M8_x_1,25_13002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_917_-_M8_x_1,25_13003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M6_x_16"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M6_x_16001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M6_x_16002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M6_x_16003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M6DIN_EN"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M6DIN_EN001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M6DIN_EN002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M6DIN_EN003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN003"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN004"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["ISO_4034_-_M8DIN_EN005"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["8232_Pneumatikverriegelung"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["8232_Pneumatikverriegelung001"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["8232_Pneumatikverriegelung002"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_Bautenzug_01.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Konsole_Bautenzug_01001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M8_x_35"].geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes["DIN_6912_-_M8_x_35001"].geometry}
      />

      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Kerbstift_Verriegelung_3.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Kerbstift_Verriegelung_3001.geometry}
      />
      <mesh
        castShadow
        material={materials["Metall.Nickel"]}
        geometry={nodes.Kerbstift_Verriegelung_3002.geometry}
      />
    </group>
  );
}

useGLTF.preload('/isotherm.glb')
