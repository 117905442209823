import React, {useEffect, useState} from 'react'
import OuterScrews from "./OuterScrews";

import { useSpring, animated } from "react-spring/three";

const Deckel = ({isLidRaised, setIsLidRaised, nodes, materials}) => {
	const [hovered, setHovered] = useState(false);

	const { scale, position } = useSpring({
    	position: isLidRaised ? [0, 0.5, 0] : [0, 0, 0],
    	config: { mass: 5, tension: 1000, friction: 300, precision: 0.00001 },
  	});

	useEffect(() => {
		document.body.style.cursor = hovered ? "pointer" : "auto";
	}, [hovered]);

	return (
    <animated.group scale={scale} position={position}>
      <OuterScrews materials={materials} nodes={nodes} />
      <mesh
        material-color="#ffffff"
        onClick={() => setIsLidRaised(!isLidRaised)}
        onPointerOver={(e) => {setHovered(true)}}
        onPointerOut={(e) => {setHovered(false)}}
        geometry={nodes.Kuppel_para_150x250.geometry}
      >
        <meshStandardMaterial
          attach="material"
          color="#ffffff"
          roughness={0.3}
          metalness={0.5}
          transparent={true}
          opacity={0.95}
        />
      </mesh>
      <mesh
        onClick={() => setIsLidRaised(!isLidRaised)}
        onPointerOver={(e) => {setHovered(true)}}
        onPointerOut={(e) => {setHovered(false)}}
        material-color="#ffffff"
        geometry={nodes.Kuppel_para_02_150x250.geometry}
      >
        <meshStandardMaterial
          attach="material"
          color="#ffffff"
          roughness={0.3}
          metalness={0.5}
          transparent={true}
          opacity={0.95}
        />
      </mesh>
    </animated.group>
  );
}

export default Deckel
