import React from 'react'

const OuterScrews = ({nodes, materials}) => {
	return (
    <group>
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK001.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK002.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK003.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK004.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK005.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK006.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK007.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK008.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK009.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK010.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK011.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK012.geometry}
      />
      <mesh
        material={materials["Plastic.Rot"]}
        geometry={nodes.Befestigungssystem_LK013.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02001.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02002.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02003.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02004.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02005.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02006.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02007.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02008.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02009.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02010.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02011.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02012.geometry}
      />
      <mesh
        material={materials["Metall.Nickel"]}
        geometry={nodes.Verschraubung_02013.geometry}
      />
    </group>
  );
}

export default OuterScrews
