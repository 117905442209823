import React from 'react'

const Box = ({position, scale=[1,1,1], ...otherProps}) => {
  // This reference will give us direct access to the mesh
  const mesh = React.useRef();

  return (
    <mesh
      {...otherProps}
      ref={mesh}
      position={position}
      scale={[0.11, 0.11, 0.11]}
    >
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

export default Box
